<template>
    <div class="menu-items">

        <router-link tag="div" to="/recharge" class="m-item mrecharge"></router-link>

        <router-link tag="div" to="/exchange" class="m-item mexchange"></router-link>


        <router-link tag="div" to="/customer_center" class="m-item mcustomer"></router-link>
        <router-link tag="div" to="/sports" class="m-item msports"></router-link>

        <router-link tag="div" to="/sports_inplay" class="m-item minplay"></router-link>

        <router-link tag="div" to="/leisure/sutda" class="m-item mminigame"></router-link>

        <router-link tag="div" to="/casinohonor" class="m-item mcasino"></router-link>

        <router-link tag="div" to="/leisure/bet365_superleague" class="m-item mbet365"></router-link>

                <router-link tag="div" to="/tgame365/tgame365highlow5s" class="m-item mtgame"></router-link>
        <router-link tag="div" to="/sports_betinfo" class="m-item msbetinfo"></router-link>


        <router-link tag="div" to="/sports_result" class="m-item mgameresult"></router-link>


        <router-link tag="div" to="/notice" class="m-item mnotice"></router-link>

        <router-link tag="div" to="/event" class="m-item mevent"></router-link>

        <router-link tag="div" to="/culcheck" class="m-item mculcheck"></router-link>

        <router-link tag="div" to="/coupon" class="m-item mcoupon"></router-link>

        <router-link tag="div" to="/message" class="m-item mmessage"></router-link>
        <router-link tag="div" to="/recmember" class="m-item mrecmember"></router-link>
        <router-link tag="div" to="" class="m-item"></router-link>
<!--        <router-link tag="div" to="" class="m-item"></router-link>-->
        <!--        <router-link tag="div" to="" class="m-item"></router-link>-->

        <!--        <router-link tag="div" to="" class="m-item"></router-link>-->

        <!--        <router-link tag="div" to="" class="m-item"></router-link>-->

    </div>
</template>

<script>
    export default {
        name: "MobileMenuItemsComp"
    }
</script>

<style scoped>

    .menu-items {
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        align-content: start;
        flex-wrap: wrap;
        height: 930px;
    }

    .m-item {
        width: 32%;
        height: 150px;
        margin-top: 5px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        position: relative;
        border-radius: 5px;
        --border: 1px solid #c6c6c6;
    }


    .msports {
        background: url(../assets/images/bg/mobile_menu/ptn/sports.png) center center no-repeat;
        background-size: 100%;
    }
    .minplay {
        background: url(../assets/images/bg/mobile_menu/ptn/inplay.png) center center no-repeat;
        background-size: 100%;
    }
    .mbet365 {
        background: url(../assets/images/bg/mobile_menu/ptn/bet365.png) center center no-repeat;
        background-size: 100%;
    }
    .mgameresult {
        background: url(../assets/images/bg/mobile_menu/ptn/gameresult.png) center center no-repeat;
        background-size: 100%;
    }
    .mminigame {
        background: url(../assets/images/bg/mobile_menu/ptn/minigame.png) center center no-repeat;
        background-size: 100%;
    }
    .mcasino {
        background: url(../assets/images/bg/mobile_menu/ptn/casino.png) center center no-repeat;
        background-size: 100%;
    }
    .mnotice {
        background: url(../assets/images/bg/mobile_menu/ptn/notice.png) center center no-repeat;
        background-size: 100%;
    }
    .mevent {
        background: url(../assets/images/bg/mobile_menu/ptn/event.png) center center no-repeat;
        background-size: 100%;
    }
    .mcoupon {
        background: url(../assets/images/bg/mobile_menu/ptn/coupon.png) center center no-repeat;
        background-size: 100%;
    }
    .mtgame {
        background: url(../assets/images/bg/mobile_menu/ptn/tgame.png) center center no-repeat;
        background-size: 100%;
    }
    .mcustomer {
        background: url(../assets/images/bg/mobile_menu/ptn/customer.png) center center no-repeat;
        background-size: 100%;
    }
    .mrecharge {
        background: url(../assets/images/bg/mobile_menu/ptn/recharge.png) center center no-repeat;
        background-size: 100%;
    }
    .mexchange {
        background: url(../assets/images/bg/mobile_menu/ptn/exchange.png) center center no-repeat;
        background-size: 100%;
    }
    .mboard {
        background: url(../assets/images/bg/mobile_menu/ptn/fboard.png) center center no-repeat;
        background-size: 100%;
    }
    .msbetinfo {
        background: url(../assets/images/bg/mobile_menu/ptn/betinfo.png) center center no-repeat;
        background-size: 100%;
    }
    .mculcheck {
        background: url(../assets/images/bg/mobile_menu/ptn/culcheck.png) center center no-repeat;
        background-size: 100%;
    }
    .mmessage {
        background: url(../assets/images/bg/mobile_menu/ptn/message.png) center center no-repeat;
        background-size: 100%;
    }
    .mrecmember {
        background: url(../assets/images/bg/mobile_menu/ptn/recmember.png) center center no-repeat;
        background-size: 100%;
    }

</style>