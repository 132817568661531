import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/ko'
Vue.use(ElementUI, { locale })

/*normalize*/
/*import animated from 'animate.css'

/!*amination*!/
Vue.use(animated)*/

/*Video Player*/
import VideoPlayer from 'vue-videojs7'

Vue.use(VideoPlayer, /* {
  options: global default videojs options,
  events: global videojs videojs events
} */)

/*Momentjs*/
import moment from 'moment';
moment.locale('kr');
Vue.prototype.$moment = moment;

/*Wechat-title*/
import VueWechatTitle from 'vue-wechat-title'
Vue.use(VueWechatTitle)

/*sweetalert2*/
import VueSweetalert2 from './plugins/vue-sweetalert2'
Vue.use(VueSweetalert2)

/*TextEditor*/
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
/*editor*/
Vue.use(VueQuillEditor);


/*Swiper*/
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
Vue.use(VueAwesomeSwiper, /* { default global options } */)

/*vue Coookies*/
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

import Toast from "./components/toast/Toast"
Vue.prototype.$toast = Toast
/*룰렛*/
import LuckDraw from 'vue-luck-draw'
Vue.use(LuckDraw)

Vue.config.productionTip = false
/*$bus*/
Vue.prototype.$bus = new Vue()

/*setting filters*/
import * as filters from './filters'
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})
/*Date Filter*/
Vue.filter('datef', function (value, formatStr = 'YYYY-MM-DD HH:mm:ss') {
  return moment(value).format(formatStr)
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
