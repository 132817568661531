import httpAgent from "../axios/httpAgent";
import httpManager from "../axios/httpManager";

export function doLogin(agent) {
    return httpAgent.post('/agent_login', agent)
}

export function getSubAgentList(search, pageNum, pageSize) {
    let url = '/sub_agent_list' + '?pageNum=' + pageNum + '&pageSize=' + pageSize + '&t=' + new Date().getTime();
    return httpAgent.get(url, search);
}

export function getAgentList(search, pageNum, pageSize) {
    let url = '/agent_list' + '?pageNum=' + pageNum + '&pageSize=' + pageSize + '&t=' + new Date().getTime();
    return httpAgent.post(url, search);
}

export function getAgentById(id) {
    let url = '/get_agent_by_id' + '?id=' + id + '&t=' + new Date().getTime();
    return httpAgent.get(url);
}

export function saveAgent2(agent) {
    return httpAgent.post('/save_agent', agent);
}

export function updateAgent2(agent) {
    return httpAgent.post('/update_agent', agent);
}

export function getRankList2(rank, pageNum, pageSize) {
    return httpAgent.post('/rank_list' + '?pageNum=' + pageNum + '&pageSize=' + pageSize, rank);
}

export function getGroupList2(entity, pageNum, pageSize) {
    return httpAgent.post('/user_group_list' + '?pageNum=' + pageNum + '&pageSize=' + pageSize, entity);
}

export function getUserList2(search, pageNum, pageSize, orderBy) {
    let url = '/agent_user_list' + '?pageNum=' + pageNum + '&pageSize=' + pageSize + '&orderBy=' + orderBy;
    return httpAgent.post(url, search);
}

export function moveagentmoney(entity, pageNum, pageSize) {
    return httpAgent.post('/agent_move_money' + '?pageNum=' + pageNum + '&pageSize=' + pageSize, entity);
}

export function getApplyList2(search, pageNum, pageSize) {
    let url = '/agent_apply_list' + '?pageNum=' + pageNum + '&pageSize=' + pageSize;
    return httpAgent.post(url, search);
}

export function getApplyAgentExchangeList(search, pageNum, pageSize) {
    let url = '/agent_subagent_exchange_history' + '?pageNum=' + pageNum + '&pageSize=' + pageSize;
    return httpAgent.post(url, search);
}


export function getLogCashPointList2(search, pageNum, pageSize) {
    let url = '/agent_list_cashpoint' + '?pageNum=' + pageNum + '&pageSize=' + pageSize;
    return httpAgent.post(url, search);
}


export function getSportsBetInfoList2(search, pageNum, pageSize, orderBy) {
    let url = '/agent_sports_bet_list' + '?pageNum=' + pageNum + '&pageSize=' + pageSize + '&orderBy=' + orderBy;
    return httpAgent.post(url, search);
}

export function getLeisureKindList2() {
    let url = '/leisure_kind_list?t=' + new Date().getTime();
    return httpAgent.get(url);
}

export function getLeisureGameList2(search, pageNum, pageSize, orderBy) {
    let url = '/agent_leisure_bet_list' + '?pageNum=' + pageNum + '&pageSize=' + pageSize + '&orderBy=' + orderBy;
    return httpAgent.post(url, search);
}

export function getHonorCasinoBetlist2(search, pageNum, pageSize) {
    let url = '/agent_casino_honor_bet_list' + '?pageNum=' + pageNum + '&pageSize=' + pageSize + '&t=' + new Date().getTime();
    return httpAgent.post(url, search);
}
export function getXimaxBetlist2(search, pageNum, pageSize) {
    let url = '/agent_casino_ximax_bet_list' + '?pageNum=' + pageNum + '&pageSize=' + pageSize;
    return httpAgent.post(url, search);
}

export function getTgameBetlist2 (search, pageNum, pageSize) {
    let url = '/agent_tgame365_bet_list' + '?pageNum=' + pageNum + '&pageSize=' + pageSize;
    return httpAgent.post(url, search);
}



export function agentExchangeCash(applyCash) {
    let url = '/agent_exchange_cash'
    return httpAgent.post(url, applyCash);
}
export function selfExchangeHistory(pageNum,pageSize) {
    let url = '/agent_self_exchange_history' + '?pageNum=' + pageNum + '&pageSize=' + pageSize;
    return httpAgent.post(url);
}

export function agentMainStatistic(agent, startTime, endTime) {
    let url = '/agent_main_statistic' + '?begin=' + startTime + '&end=' + endTime;
    return httpAgent.post(url, agent);
}

export function agentLogout() {
    let url = '/logout' + '?t=' + new Date().getTime();
    return httpAgent.get(url);
}





