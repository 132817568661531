<template>
    <div class="overlay" :class="{'showRegisterPannel':$store.state.isShowRegisterPanel && !$store.state.isLogin}">
        <div class="overlay-warp">
            <div class="overlay-open">

                <div class="register-warp">
                    <div class="login-head">
                        <h4>회원가입</h4>
                        <div class="login-panel-close" @click="closePanel">
                            <img src="../assets/images/icon/common/close1.svg"/>
                        </div>
                    </div>
                    <div class="register-cont">
                        <div class="register-d">
                            <div class="register-left">
                                <div class="register-logo">
                                    <img src="../assets/images/logo/ptn/logo01.png" alt="">
                                </div>
                                <div class="register-from">
                                    <div style="display: flex;justify-content: space-between;flex-wrap: nowrap">
                                        <div class="form-field">
                                            <span>아이디</span>
                                            <input type="text" v-model="user.username" placeholder="4-15자리 영문,숫자조합"/>
                                        </div>
                                        <div class="form-field">
                                            <span>닉네임</span>
                                            <input type="text" v-model="user.nickname" placeholder=" 3-8자리 한글,영문,숫자"/>
                                        </div>
                                    </div>
                                    <div style="display: flex;justify-content: space-between;flex-wrap: nowrap">
                                        <div class="form-field">
                                            <span>비밀번호</span>
                                            <input type="password" v-model="user.passwd" placeholder="4-20자리 영문,숫자"/>
                                        </div>
                                        <div class="form-field">
                                            <span>비밀번호확인</span>
                                            <input type="password" v-model="user.confirm_password"
                                                   placeholder="비밀번호확인"/>
                                        </div>
                                    </div>
                                    <div style="display: flex;justify-content: space-between;flex-wrap: nowrap">
<!--                                        <div class="form-field">-->
<!--                                            <span>통신사</span>-->
<!--                                            <select style="font-size: 13px;padding: 3px 0" v-model="user.telcomp">-->
<!--                                                <option :value="null">- - - 통신사선택 - - -</option>-->
<!--                                                <option value="SKT">SKT</option>-->
<!--                                                <option value="LG">LG</option>-->
<!--                                                <option value="KT">KT</option>-->
<!--                                                <option value="알뜰SKT">알뜰SKT</option>-->
<!--                                                <option value="알뜰LG">알뜰LG</option>-->
<!--                                                <option value="알뜰KT">알뜰KT</option>-->
<!--                                            </select>-->
<!--                                        </div>-->
                                        <div class="form-field">
                                            <span>휴대폰</span>
                                            <input type="number" v-model="user.phone" placeholder="“-” 없이 숫자만 입력"/>
                                        </div>
                                        <div class="form-field">
                                            <button @click="getVerifyPhoneCode"
                                                    style="height: 28px;background-color: #ffa500;border-radius: 3px;color: #0c0e0e">
                                                인증하기
                                            </button>
                                        </div>
                                    </div>
                                    <div style="display: flex;justify-content: space-between;flex-wrap: nowrap">
                                        <div class="form-field">
                                            <span>인증코드</span>
                                            <input type="text" v-model="user.verificationCode" placeholder="휴대폰 인증코드"/>
                                        </div>
                                        <div class="form-field">
                                            <span>예금주</span>
                                            <input type="text" v-model="user.beneficiary" placeholder="환전 받으실 예금주명"/>
                                        </div>
                                    </div>
                                    <div style="display: flex;justify-content: space-between;flex-wrap: nowrap">
                                        <div class="form-field">
                                            <span>은행선택</span>
                                            <select style="font-size: 13px;padding: 3px 0" v-model="user.bank">
                                                <option :value="null">- - - 은행선택 - - -</option>
                                                <option value="카카오뱅크">카카오뱅크</option>
                                                <option value="신한">신한은행</option>
                                                <option value="국민">국민은행</option>
                                                <option value="농협">농협</option>
                                                <option value="우리">우리은행</option>
                                                <option value="하나">하나은행</option>
                                                <option value="기업">기업은행</option>
                                                <option value="우체국">우체국</option>
                                                <option value="대구">대구은행</option>
                                                <option value="경남">경남은행</option>
                                                <option value="광주">광주은행</option>
                                                <option value="부산">부산은행</option>
                                                <option value="산림조합">산림조합</option>
                                                <option value="산업">산업은행</option>
                                                <option value="수협">수협은행</option>
                                                <option value="신협">신협은행</option>
                                                <option value="전북">전북은행</option>
                                                <option value="제주">제주은행</option>
                                                <option value="씨티">씨티은행</option>
                                                <option value="새마을">새마을금고</option>
                                                <option value="토스뱅크">토스뱅크</option>
                                                <option value="케이뱅크">케이뱅크</option>
                                                <option value="기타">기타</option>
                                            </select>
                                        </div>
                                        <div class="form-field">
                                            <span>계좌번호</span>
                                            <input type="number" v-model="user.acNo" pattern="[0-9]*"
                                                   placeholder="“-” 없이 숫자만 입력"/>
                                        </div>
                                    </div>

                                    <div style="display: flex;justify-content: space-between;flex-wrap: nowrap">

                                        <div class="form-field">
                                            <span>생일 년/월/일</span>
                                            <div>
                                                <select style="font-size: 13px;padding: 3px 0" v-model="user.byear">
                                                    <option :value="null">=년도=</option>
                                                    <option :value="item" v-for="item in syear">{{item}}</option>
                                                </select>
                                                <select style="font-size: 13px;padding: 3px 0;margin: 0 1px" v-model="user.bmonth">
                                                    <option :value="null">=월=</option>
                                                    <option :value="item" v-for="item in smonth">{{item}}</option>
                                                </select>
                                                <select style="font-size: 13px;padding: 3px 0" v-model="user.bday">
                                                    <option :value="null">=일=</option>
                                                    <option :value="item" v-for="item in sday">{{item}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="form-field">
                                            <span>가입코드</span>
                                            <input type="text" v-model="user.code" placeholder="총판,회원의 추천코드"/>
                                        </div>
                                    </div>
                                    <div style="display: flex;justify-content: space-between;flex-wrap: nowrap">
                                        <div class="form-field">
                                            <button class="register" @click="doRegister"
                                                    v-if="!clickedLogin">가입하기
                                            </button>
                                        </div>
                                    </div>

                                </div>
                                <div class="error-message" v-show="erm != null">
                                    <i class="fa fa-warning"></i> {{erm}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>
</template>

<script>
    import {loginRegisterMixin} from "../common/mixin";
    import {getVerificationCode, register} from "../network/userRequest";
    import LoadingLoginComp from "./LoadingLoginComp";
    import {RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING} from "../store/mutation-types";

    export default {
        name: "RegisterComp",
        components: {LoadingLoginComp},
        mixins: [loginRegisterMixin],
        data() {
            return {
                clickedLogin: false,
                user: {
                    username: '',
                    passwd: '',
                    confirm_password: '',
                    nickname: '',
                    phone: '',
                    verificationCode: '',
                    bank: null,
                    beneficiary: '',
                    acNo: '',
                    extractPasswd: '',
                    code: '',
                    telcomp: null,
                    byear:null,
                    bmonth:null,
                    bday:null,
                },
                error: {
                    username: '',
                    passwd: '',
                    confirm_password: '',
                    nickname: '',
                    phone: '',
                    verificationCode: '',
                    bank: '',
                    beneficiary: '',
                    acNo: '',
                    extractPasswd: '',
                    code: '',
                    telcomp: null
                },
                status: {
                    username: false,
                    passwd: false,
                    confirm_password: false,
                    nickname: false,
                    phone: false,
                    verificationCode: false,
                    bank: false,
                    beneficiary: false,
                    acNo: false,
                    extractPasswd: false,
                    code: false
                },
                isUsePhoneCode: false,
                isShowMessage: false,
                content: '',
                erm: null,
                syear: [],
                smonth: [],
                sday: [],
            }
        },
        methods: {
            closePanel() {
                this.$store.state.isShowLoginPanel = false;
                this.$store.state.isShowRegisterPanel = false;
            },
            doRegister() {
                this.clickedLogin = true
                this.erm = null
                if (!this.checkConfirmPassword()) {
                    this.erm = '입력하신 비밀번호가 동일하지 않습니다'
                    this.clickedLogin = false
                    return false;
                }

                register(this.user).then(res => {
                    if (res.data.success) {
                        this.$store.state.isShowLoginPanel = false
                        this.$store.state.isShowRegisterPanel = false
                        this.$swal({
                            title: '회원가입을 축하드립니다.관리자 확인후 이용가능합니다',
                            type: 'success',
                            showCancelButton: false,
                            showConfirmButton: true
                        })

                    } else {
                        this.erm = res.data.msg
                    }
                    this.clickedLogin = false

                })

            },
            getVerifyPhoneCode() {
                if (this.checkPhone()) {
                    this.$store.commit(RECEIVE_SHOW_LOADING)
                    getVerificationCode(this.user.phone).then(res => {
                        this.$store.commit(RECEIVE_HIDE_LOADING)
                        if (res.data.success) {
                            this.$swal({
                                title: '휴대폰 인증코드가 전송되였습니다. 휴대폰 문자를 확인해주세요',
                                type: 'success',
                                background: '#ffffff',
                                showCancelButton: false,
                                showConfirmButton: true
                            })
                        } else {
                            this.erm = res.data.msg
                        }

                    })
                }
            },
            checkUserName() { //회원가입시 아이디 체크
                if (!this.testRegxUserName) {
                    this.error.username = '아이디는 4-15자리 영문,숫자조합입니다'
                    this.status.username = false;
                    return false;
                }
                this.error.username = '';
                return true;
                /*//아이디 사용가능 여부 체크
                this.$store.commit(RECEIVE_SHOW_LOADING)
                checkUserName(this.user.username).then(res => {
                    if (res.data.success) {
                        this.error.username = ''
                        this.status.username = true;
                    } else {
                        this.error.username = res.data.msg
                        this.status.username = false;
                    }
                    this.$store.commit(RECEIVE_HIDE_LOADING)
                })*/
            },
            checkNickName() { //회원가입시 닉네임 체크
                if (!this.testRegxNickname) {
                    this.error.nickname = '닉네임은 2-6자리 한글만 가능합니다'
                    this.status.nickname = false;
                    return false;
                }
                this.error.nickname = '';
                return true;
                //아이디 사용가능 여부 체크
                /*this.$store.commit(RECEIVE_SHOW_LOADING)
                checkNickName(this.user.nickname).then(res => {
                    if (res.data.success) {
                        this.error.nickname = ''
                        this.status.nickname = true;
                    } else {
                        this.error.nickname = res.data.msg
                        this.status.nickname = false;
                    }
                    this.$store.commit(RECEIVE_HIDE_LOADING)
                })*/
            },
            checkPasswd() {
                if (!this.testRegxPasswd) {
                    this.error.passwd = '비밀번호는 4-20자리 입니다';
                    this.status.passwd = false;
                    return false;
                }
                this.error.passwd = '';
                this.status.passwd = true;
                return true;
            },
            checkConfirmPassword() {
                if (this.user.confirm_password.trim() === '' || this.user.passwd.trim() !== this.user.confirm_password.trim()) {
                    this.error.confirm_password = '입력하신 비밀번호가 동일하지 않습니다'
                    this.status.confirm_password = false;
                    return false;
                }
                this.error.confirm_password = '';
                this.status.confirm_password = true;
                return true;

            },
            checkPhone() {
                if (!/^[0-9]{11,12}$/.test(this.user.phone)) {
                    this.error.phone = '폰번호 오류입니다'
                    this.status.phone = false;
                    return false;
                }
                this.error.phone = '';
                this.status.phone = true;
                return true;

            },
            checkBank() {
                if (this.user.bank === '') {
                    this.error.bank = '은행명을 선택하세요'
                    this.status.bank = false;
                    return false;
                }
                this.error.bank = ''
                this.status.bank = true;
                return true;
            },
            checkBeneficiary() {
                if (!/^.{1,10}$/.test(this.user.beneficiary)) {
                    this.error.beneficiary = '예금주를 입력하세요'
                    this.status.beneficiary = false;
                    return false;
                }
                this.error.beneficiary = ''
                this.status.beneficiary = true;
                return true;

            },
            checkAcNo() {
                if (!/^[0-9]{5,20}$/.test(this.user.acNo)) {
                    this.error.acNo = '계좌번호 오류입니다.\'-\'없이 숫자로만 입력하세요'
                    this.status.acNo = false;
                    return false;
                }
                this.error.acNo = '';
                this.status.acNo = true;
                return true;

            },
            checkCode() {
                if (!/^[0-9a-zA-Z]{2,50}$/.test(this.user.code)) {
                    this.error.code = '추천인 코드를 정확하게 입력하세요';
                    this.status.code = false;
                    return false;
                }
                this.error.code = '';
                this.status.code = true;
                return true;
            }
        },
        created() {
            let b = 1949
            for (let i = 1; i <= 71; i++) {
                let bb = b+i;
                this.syear.push(bb)
            }
            for (let i = 1; i <= 12; i++) {
                this.smonth.push(i)
            }
            for (let i = 1; i <= 31; i++) {
                this.sday.push(i)
            }

        },
        computed: {
            allowUsername() {
                return /^[a-z][0-9a-z]{3,20}$/.test(this.username)
            },
            allowPasswd() {
                return /^.{3,30}$/.test(this.passwd)
            }
        }
    }
</script>

<style scoped>

    @media screen and (max-width: 1024px) {
        .register-warp {
            width: 390px !important;
            height: 600px !important;
            background-color: #fff9ff;
        }

        .register-warp .register-cont .register-d .register-left {
            width: 100% !important;
        }

        .register-warp .register-cont .register-d .register-right {
            display: none !important;
        }
    }

    .showRegisterPannel {
        transform: translateY(0%);
    }

    .register-warp {
        width: 400px;
        height: 600px;
        background-color: var(--loginWarpBg);
        color: #fff9ff;
    }

    .register-warp .login-head {
        background-color: var(--loginWarpHeadBg);
        color: #fff;
        height: 40px;
        line-height: 40px;
        padding: 0 16px;
        position: relative;
        text-transform: uppercase;
        width: 100%;
        z-index: 5002;
    }

    .register-warp .login-head h4 {
        -webkit-align-items: center;
        align-items: center;
        display: -webkit-inline-flex;
        display: inline-flex;
        font-size: 14px;
        font-weight: 700;
        -webkit-justify-content: center;
        justify-content: center;
        margin: 0;
        vertical-align: middle;
    }

    .login-panel-close {
        position: absolute;
        top: 0;
        right: 0;
        width: auto;
        height: 40px;
        padding: 2px;
        cursor: pointer;
    }

    .login-panel-close img {
        height: 30px;
    }

    .register-warp .register-cont {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        overflow: hidden;
        position: relative;
        z-index: 5001;
        margin-top: -40px;
    }

    .register-warp .register-cont .register-d {
        display: flex;
        width: 100%;
        height: 100%;
        padding-top: 40px;
    }

    .register-warp .register-cont .register-d .register-left {
        width: 100%;
        height: 100%;
        overflow: hidden;
        z-index: 5002;
        position: relative;
    }

    .error-message {
        text-align: center;
        font-size: 14px;
        font-weight: bold;
        color: #ff0000;
        animation: sansan 700ms infinite;
        -webkit-animation: sansan 700ms infinite;
    }

    .register-warp .register-cont .register-d .register-left .register-logo {
        text-align: center;
        margin-top: 10px;
    }

    .register-warp .register-cont .register-d .register-left .register-logo img {
        height: 60px !important;
    }

    .register-warp .register-cont .register-d .register-left .register-from {
        width: 100%;
        padding: 0 16px 16px;
    }

    .register-warp .register-cont .register-d .register-left .register-from .form-field {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 44px;
        margin-top: 20px;
        padding: 0 2px;
    }

    .register-warp .register-cont .register-d .register-left .register-from .form-field span {
        height: 28px;
        line-height: 28px;
        font-weight: bold;
        font-size: 13px;
        color: #575757;
    }

    .register-warp .register-cont .register-d .register-left .register-from .form-field input {
        background: 0 0;
        border: 0;
        border-bottom: 1px solid #a3a1a7;
        border-radius: 0;
        color: #060606;
        font-size: 15px;
        height: 25px;
        line-height: 25px;
        outline: 0;
        padding: 0;
        width: 100%;
        text-align: center;
    }

    .register-warp .register-cont .register-d .register-left .register-from-button {
        width: 100%;
        padding: 0 16px 16px;
        margin-top: 5px;
    }

    .register-warp .register-cont .register-d .register-left .register-from-button button {
        width: 100% !important;
    }

    .register-warp .register-cont .register-d .register-left .login {
        height: 36px;
        line-height: 36px;
        font-size: 14px;
        font-weight: bold;
    }

    .register-warp .register-cont .register-d .register-left .register {
        width: 100%;
        height: 36px;
        line-height: 36px;
        font-size: 14px;
        background-color: var(--regBtnBg);
        --border: 2px solid var(--sportsKindButtonBorderColor);
        font-weight: 600;
        color: #000000;
        margin-top: 10px;
    }

    .register-warp .register-cont .register-d .register-left .tel-message {
        text-align: center;
        font-size: 14px;
        font-weight: bold;
        color: #0b1423;
        line-height: 28px;
    }

    .register-warp .register-cont .register-d .register-left .login-bottom {
        width: 100%;
        background-color: #2b2f35;
        position: absolute;
        bottom: 0;
        padding: 16px;
    }

    .register-warp .register-cont .register-d .register-left .login-bottom .join-text {
        color: #fff9ff;
        font-size: 12px;
    }

    /*login panel right*/
    .register-warp .register-cont .register-d .register-right {
        width: 50%;
        height: 100%;
        overflow: hidden;
        z-index: 5002;
    }

    @keyframes sansan {
        from {
            opacity: 1.0;
        }
        70% {
            opacity: 0.8;
        }
        50% {
            opacity: 0.6;
        }
        to {
            opacity: 1.0;
        }
    }

    @-webkit-keyframes sansan {
        from {
            opacity: 1.0;
        }
        50% {
            opacity: 0.4;
        }
        to {
            opacity: 1.0;
        }
    }
    .swal2-container{
        z-index: 5100!important;
    }

</style>